
 export class VehicleModel {

    VehCode: string;
    VehTrailer_VehCode: string;
    VehTrailerVehCode: string;
    VehYear: number;
    VehTare: number;
    VehCapacity: number;
    DriverName: string;
    DriverCode: string;
    CarrierName: string;
    CarrierCode: string;
    VtyCode: string;
    VtyName: string;
    VcoCode: string;
    VcoName: string;
    StaCode: string;
    StaName: string;
    VcnCode: string;
    VcnName: string;
    VbrCode: string;
    VbrName: string;
    bodyworkCode: string;
    VbwCode: string;
    VehReference: string;
    VehReference1: string;

 public constructor(init?: Partial<VehicleModel>) {
     Object.assign(this, init);
 }
}
