export class AgreementModel {
    truckplate: string;
    truckisok: boolean;
    traillerplate: string;
    traillerisok: boolean;
    driverid: string;
    drivername: string;
    driverisok: boolean;


    public constructor(init?: Partial<AgreementModel>) {
        Object.assign(this, init);
        this.driverisok = false;
        this.traillerisok = false;
        this.driverisok = false;
    }
}
